import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import Section from '@components/Section';
import Wrapper from '@components/Wrapper';
import Headings from '@components/Headings';
import Paragraph from '@components/Paragraph';
import HeadingsContainer from '@components/Headings/Headings.Container';
import Transitions from '@components/Transitions';
import PrivacyPolicyHero from '@sections/subpage/Subpage.Hero';

const TermsAndConditionsPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = false;

    return (
    <Layout id="terms-and-conditions" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'terms-title' })}
            /*description={intl.formatMessage({ id: 'seo-meta-description'})}*/
            slug='/terms-and-conditions/'
        />
        <Transitions.CSS.FadeIn as={Section}>
                <Wrapper>
                    {/*<HeadingsContainer center>*/}
                        <Headings.h2 center>{intl.formatMessage({ id: 'terms-title' })}</Headings.h2>
                        {/*<Paragraph center lead>{intl.formatMessage({ id: 'services-subtitle' })}</Paragraph>*/}
                    {/*</HeadingsContainer>*/}
                </Wrapper>
        </Transitions.CSS.FadeIn>
    </Layout>
    )
}

export default TermsAndConditionsPage;
